import React from "react";
import { Container, Row, Col } from "reactstrap";
import picture1 from "../assets/img/Picture1.png";
import picture2 from "../assets/img/Picture2.png";
import picture3 from "../assets/img/Picture3.png";

function Info() {
    return (
        <Container className="mt-5">
            <Row>
                <Col md={10} className="m-auto text-center">
                    <h1 className="infoHeading">INFO</h1>
                    <h3 className="subHeading">
                        NFTs are coming soon to FHP <br /> What’s so cool about
                        them?
                    </h3>
                    <p align="justify">
                        NFTs (non-fungible tokens) from FHP are unique
                        cryptographic tokens that exist on a blockchain and
                        cannot be replicated. You can treasure these NFTs, such
                        as to remember events you shared with your favorite
                        student athlete. You can trade with your friends. NFTs
                        can help you commemorate games your favorite athlete
                        played, and championships won. You will enjoy.
                    </p>
                </Col>
            </Row>
            <Row className="ml-5 pl-5 d-flex justify-content-between imagesRow  m-auto">
                <Col md={4} className="pl-5 imagesRow_img1">
                    <img
                        src={picture1}
                        height="200px"
                        width="200px"
                        alt=""
                        className="mb-2"
                    />
                    <h4 className="mt-5 pt-5">Patent pending</h4>
                </Col>
                <Col md={4} className="mt-5 pt-5 imagesRow_img">
                    <img src={picture2} height="250px" width="250px" alt="" />
                </Col>
                <Col md={4} className="imagesRow_img">
                    <img src={picture3} height="auto" width="250px" alt="" />
                </Col>
            </Row>
            <Row className="mt-5 m-auto">
                <Col md={10} className="m-auto pt-4 ">
                    <p>
                        <b>ADVISORY:</b> These materials and comments are for
                        general information purposes only and are not investment
                        advice or solicitation to buy, sell or hold any
                        cryptoasset or to engage in any specific trading
                        strategy. Some crypto products and markets are
                        unregulated and you may not be protected by government
                        compensation and/or regulatory protection schemes. The
                        unpredictable nature of the cryptoasset markets can lead
                        to loss of funds. Tax may be payable on any return
                        and/or on any increase in the value of your cryptoassets
                        and you should seek independent advice on your taxation
                        position.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Info;
