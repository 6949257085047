const Main = (props) => {
    return (
        <>
            {/* <header>Main Header</header> */}
            {props.children}
            {/* <footer>Main Footer</footer> */}
        </>
    );
};

export default Main;
