import { Link } from "react-router-dom";
import downArrow from "../assets/img/downArrow.png";
import digitalWallet from "../assets/img/digital_Wallet.jpg";
import { Container, Row } from "reactstrap";
import picture1 from "../assets/img/Picture1.png";
const Landing = (props) => {
    return (
        <>
            <Container className="mt-5 ">
                <div className="mobileViewDiv m-auto position-relative">
                    <div className="position-absolute d-flex  landingTopDiv">
                        <div className="MobileViewtopDiv"></div>
                        <Link class="btn btn-link" to="/landing">
                            Info
                        </Link>
                    </div>
                    <div className="MobileViewDiv__insideDiv">
                        <div className="d-flex justify-content-between p-2">
                            <b>NFT</b>
                            <b>Digital Asset</b>
                        </div>
                        <img
                            className="d-flex justify-content-center align-items-center landingImageTop"
                            src={picture1}
                            height="200px"
                            width="200px"
                            alt=""
                        />
                        <div className="pl-2 d-flex landingCenteredDiv ">
                            <b>
                                Site Under <br />
                                Construction
                            </b>
                            <img
                                src={downArrow}
                                height="40px"
                                width="40px"
                                className="landingCenteredDiv__downArrow"
                                alt=""
                            />
                        </div>

                        <img
                            className="d-flex justify-content-center align-items-center p-3 landingPage__bottomDiv "
                            src={digitalWallet}
                            height="150px"
                            width="250px"
                            alt=""
                        />
                    </div>
                    <div className="position-absolute d-flex landingBottomDiv">
                        <b className="landingBottomText fw-bold">
                            F<span className="text-info">H</span>
                            <span className="text-danger">P</span>NFT
                        </b>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Landing;
